@import '~@holberg/ui-kit/src/scss/core.scss';

.report-tile-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .report-info-icon {
        margin-right: 8px;
        outline: none;
        height: 20px;
        padding: 0 4px;

        path {
            fill: $N800
        }
    }

    .row {
        &:not(:last-child) {
            margin-right: 20px;
        }

        span {
            margin-right: 6px;
        }
    }
}

.report-tile-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 37px;

    .report-actions {
        display: flex;

        button {
            margin-left: 10px;
            height: 36px;
            width: 36px;
            padding: 8px;

            path {
                fill: $R550
            }
        }
    }

    .report-tile-info-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .report-status {
            display: flex;
            align-items: center;
            border-right: 1px solid $N200;
            padding-right: 12px;
            margin-right: 12px;
            width: 290px;

            span {
                @extend %font-h2;
                font-weight: 600;
                margin-right: 16px;
            }

            div {
                @extend %font-title
            }

            .report-checkbox {
                span {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .report-status {
        border-color: transparent !important;
    }

    .report-tile-info {
        margin-bottom: 25px;
    }
}

@media (max-width: 1579px) {
    .report-info-icon {
        margin-right: 14px !important;
        padding: 0 !important;
    }
}