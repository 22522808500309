@import '~@holberg/ui-kit/src/scss/core.scss';

.filters-controls {
  background: $N50;
  padding: 18px;

  .button {
    padding: 8px 16px;

    &:last-child {
      margin-left: 8px;
    }
  }

  .buttons {
    display: flex;
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .info-icon {
      width: 16px;
      height: 16px;

      path {
        fill: $N800;
      }
    }

    .info-message {
      @extend %font-small-medium;

      margin-left: 8px;
      color: $N800;
    }
  }
}
