@import '~@holberg/ui-kit/src/scss/core.scss';

.label {
  @extend %font-text-medium-mono;

  color: $N800;
  display: flex;
  margin-bottom: 4px;
}

.icon {
  flex-basis: 20px;
  height: 20px;
  margin: 0 6px 0 0;

  > path {
    fill: $N800;
  }
}

.text {
  flex: 1;
}
