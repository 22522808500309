@import '~@holberg/ui-kit/src/scss/core.scss';

.tooltip-content {
    min-width: 280px !important
}

.template-container {
    @extend %font-small-medium;
    padding: 8px 4px;

    .rows-divider {
        margin-bottom: 16px;

        .row {
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;

            span {
                flex: 1;

                &:first-child {
                    color: $N300
                }

                &:last-child {
                    color: white;
                }
            }
        }
    }

    .tooltip-message {
        background: $Y150;
        color: $N900;
        border-radius: 4px;
        padding: 6px;
        margin-bottom: 18px;
    }

    .badge-div {
        margin-top: 24px;

        .badge {
            @extend %font-small-medium;

            text-transform: capitalize;
            padding: 2px 5px;
            margin: 0 5px 0 0;
        }
    }
}