@import '~@holberg/ui-kit/src/scss/core.scss';

.finding {
  border: 2px solid transparent;

  .shopping-cart {
    margin: 0 50px 0 44px;
    padding: 8px 0;
  }

  .content {
    padding-left: 56px;
  }

  .modal {
    .header {
      @extend %font-text-semibold;

      padding-bottom: 24px;
    }

    .warning {
      path {
        fill: $Y600;
      }
    }
  }
}

.findingReorderSpace {
  @extend %font-text-semibold;
  color: $N300;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.04em;
  height: 8px;
  background: $N100;
  transition: all ease-out 0.2s;
}

.findingReorderSpace-hover {
  height: 40px;
}
