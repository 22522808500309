@import '~@holberg/ui-kit/src/scss/core.scss';

.form-row {
  margin-bottom: 3px;

  .button {
    padding: 4px;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .main-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .hidden {
      visibility: hidden;
    }
  }

  .input-container {
    width: 91%;
  }
}