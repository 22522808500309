@import '~@holberg/ui-kit/src/scss/core.scss';

.table-settings {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .cog-button {
    margin-left: 16px;
  }

  .cog-popup, .filter-popup {
    top: 48px;
    position: absolute;
    right: 0;
  }

  .filter-popup {
    min-width: 285px;
  }
}
