@import '~@holberg/ui-kit/src/scss/core.scss';

.container {
  margin-bottom: 12px;

  .warning {
    border-radius: 6px;
    margin: 24px 0;
  }

  .card {
    background-color: $White;
    border-radius: 4px;
  }
}
