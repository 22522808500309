@import '~@holberg/ui-kit/src/scss/core.scss';

.container {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .title {
    @extend %font-text-medium-mono;

    color: $N800;
    margin-bottom: 8px;
    margin-top: 0;
  }

  .categorical-container {
    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .label {
      display: inline-flex;

      .checkmark {
        top: 4px;
        bottom: auto;
      }
    }

    .label-active {
      color: $N1000;
    }
  }
}
