@import '~@holberg/ui-kit/src/scss/core.scss';
$nav-header-height: 57px;

.navigation-header {
  box-sizing: border-box;
  width: 100%;
  height: $nav-header-height;
  display: flex;
  padding: 0 24px 0 32px;
  align-items: center;
  background-color: $White;
  border-bottom: 1px solid $N150;
  position: fixed;
  top: 0;
  z-index: 2;

  .tabs {
    margin-left: 25px;
    display: flex;
    align-items: flex-end;

    .tab {
      text-decoration: none;
    }
  }

  .controls {
    margin-left: auto;
    display: flex;
  }

  .user-heading {
    cursor: initial;
    user-select: text;

    &:active {
      background: none;
    }
  }
}