@import '~@holberg/ui-kit/src/scss/core.scss';

.flash-report-border {
  animation: flash 1.5s;
  animation-iteration-count: 3;
}

@keyframes flash {
  0% {
    border-color: $Pr150;
  }

  100% {
    border-color: transparent;
  }
}

.report-tile {
  border-radius: 16px;
  background: $White;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid;
  border-color: transparent;
  cursor: pointer;

  .icon {
    margin-right: 8px;
  }

  .flex-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .report-tile-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .diagnostic-div {
      @extend %font-text-medium;
      color: $N900;

      .div-heading {
        display: flex;
        align-items: center;
        margin: 0;
        line-height: 38px;

        svg {
          height: 20px;
        }

        path {
          fill: $N800;
        }
      }

      .diagnostic-title {
        @extend %font-text-semibold;
        color: $N1000;
        margin: 6px 0 5px 0;
        font-weight: 700;
      }

      .diagnostic-freetext {
        font-style: italic;
      }
    }
  }

  .report-accordion {
    margin-right: 34px;

    .report-accordion-header {
      @extend %font-text-medium;
      color: $N900;
      padding: 0px 15px;
      background: $N100;
      box-shadow: 0px 2px 2px 0px #e3e3e3;
      border-radius: 8px;
      width: 330px;
      height: 38px;
    }

    .expanded-accordion-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .report-accordion-content {
      background: $N50;
      padding: 10px;
      margin-top: 1px;
      max-height: 200px;
      overflow: auto;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 330px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: $N100;
      }

      &::-webkit-scrollbar-thumb {
        background: $N300;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $N800;
      }
    }
  }
}

@media (max-width: 1100px) {
  .report-accordion {
    margin-bottom: 15px;
  }
}