@import '~@holberg/ui-kit/src/scss/core.scss';

.example-header {
  display: flex;
  margin-right: 32px;
  padding: 8px 12px;
  border-radius: 2px;
  background: transparent;

  .arrow {
    margin-right: 10px;
  }

  .label {
    @extend %font-text-regular;

    color: $N900;
    line-height: 1.8;
    max-width: 60%;
    margin: 0 10px 0 8px;
  }

  &:hover {
    @extend %transition-all;

    cursor: default;
    background: $N50;
  }
}
