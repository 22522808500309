@import '~@holberg/ui-kit/src/scss/core.scss';
@import 'components/NavigationHeader/NavigationHeader.module.scss';

$patient-details-header-height: 40px;
$reports-section-header-height: 68px;
$warning-msg-height: 24px;
$snack-bar-height: 40px;
$reports-section-height: calc(100vh - (#{$patient-details-header-height} + #{$nav-header-height} + #{$warning-msg-height}));
$reports-scroll-div-height: calc(#{$reports-section-height} - #{$reports-section-header-height});

.wrapper {
    @extend %transition-all;
}

.snack-wrapper {
    @extend %transition-all;

    .reports-section {
        min-height: calc(#{$reports-section-height} - #{$snack-bar-height}) !important;

        .reports-scroll-div {
            height: calc(#{$reports-scroll-div-height} - #{$snack-bar-height}) !important;
        }
    }
}

.flex-center {
    display: flex;
    align-items: center;
}

.patient-overview-page {
    display: flex;

    .reports-section {
        background: $N100;
        width: 100%;
        min-height: $reports-section-height;

        .reports-section-header {
            justify-content: space-between;
            padding: 16px 20px;

            .reports-section-header-actions {
                div {
                    &:first-child {
                        border-right: 1px solid $N200;
                        padding-right: 2px;
                    }
                }

                button {
                    margin-left: 8px;
                }

                .unreport-btn {
                    background-color: $N150;

                    &:hover {
                        background-color: $N200;
                        color: $N1000;
                    }
                }
            }
        }

        .reports-scroll-div {
            padding: 2px 16px;
            overflow: auto;
            height: $reports-scroll-div-height;
        }
    }

    .section-title {
        @extend %font-h2;
        font-weight: 600;
        color: $N900;
        margin: 0;
    }
}