.g {
  outline: none;
}

.path {
  outline: none;

  &:hover {
    fill: #e7edfd !important;
  }
}

.node {
  pointer-events: none;
}
