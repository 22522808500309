@import '~@holberg/ui-kit/src/scss/core.scss';

.report-section {
  @extend %font-text-medium;

  height: 100%;

  .container {
    background-color: $White;
    overflow: auto;
    height: 100%;
  }

  .content {
    color: $N900;
  }

  .error-container {
    background-color: $White;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .error {
      color: $N1000;
      max-width: 152px;
    }
  }

  .report {
    width: 100%;
    height: 100%;
  }
}
