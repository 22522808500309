@import '~@holberg/ui-kit/src/scss/core.scss';

.tab-content {
  max-width: 750px;
  outline: none;
  width: 100%;

  .content-header {
    display: flex;
    padding: 32px;
    border-bottom: 1px solid $N150;

    .row-title {
      @extend %font-label;

      text-transform: uppercase;
    }

    .row:not(:last-child) {
      margin-right: 40px;
    }
  }

  .form {
    padding-bottom: 32px;
  }

  &:focus {
    border: none;
  }
}
