@import '~@holberg/ui-kit/src/scss/core.scss';

.content {
  width: 580px;
}

.modal {
  $height: 460px;

  padding: 0;

  .wrap {
    padding: 16px 24px 24px;
  }

  .search {
    margin: 16px 0;
    width: 100%;

    &-input {
      width: 100%;
    }

    &-icon {
      width: 16px;
      height: 16px;
    }

    .search-icon {
      margin-left: 14px;
    }
  }

  .tree-wrap {
    border-top: 1px solid $N150;
    height: $height;
    margin: 0 -24px;
    position: relative;

    .tree {
      max-height: $height;
      padding-top: 16px;
    }

    .tree:first-child {
      overflow-y: auto;
    }
  }

  .label {
    max-height: calc(100% - 86px)
  }

  .action-label {
    white-space: nowrap;
  }
}
