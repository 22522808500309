@import '~@holberg/ui-kit/src/scss/core.scss';
@import 'components/NavigationHeader/NavigationHeader.module.scss';

.layout {
  padding-top: $nav-header-height;

  .error-wrapper {
    min-height: calc(100vh - #{$nav-header-height});
  }
}
