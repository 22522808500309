@import '~@holberg/ui-kit/src/scss/core.scss';

$gutter: 8px;

.properties-tab {
  .title {
    @extend %font-h2;
    color: $N900;
    margin: 0 0 16px;
  }

  .masonry {
    display: flex;
    margin-left: -$gutter;
    width: auto;
  }
  .column {
    padding-left: $gutter;
  }
}
