@import '~@holberg/ui-kit/src/scss/core.scss';

.study-tabs-section {
  height: 100%;
}

.study-tabs-wrap {
  background: $White;
  border-radius: 8px;
  display: flex;
  height: 100%;
  justify-content: left;
  overflow-y: auto;
}
