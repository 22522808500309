@import '~@holberg/ui-kit/src/scss/core.scss';

.columns-list {
  overflow: scroll;
  padding-bottom: 6px;
  max-height: calc(100vh - 264px);

  .pinned {
    @extend %transition-all;

    height: auto;
    overflow: visible;
    padding: 12px 0 16px;
    margin-bottom: 12px;
    border-bottom: 1px solid $N150;
  }

  .hidden {
    @extend %transition-all;

    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 0;
    border-bottom: transparent;
  }

  .pin-label {
    @extend %font-text-regular;

    display: inline-block;
    margin: 0 0 8px 16px;
    color: $N800;
    text-transform: uppercase;
  }
}
