@import '~@holberg/ui-kit/src/scss/core.scss';

.hidden {
  display: none;
}

:export {
  White: $White;
  N200: $N200;
}
