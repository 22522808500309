@import '~@holberg/ui-kit/src/scss/core.scss';

.panel-input {
  position: relative;

  &.disabled * {
    user-select: none;
    cursor: not-allowed;

    > path {
      fill: $N200;
    }
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .wrap {
    margin-bottom: 0;
  }

  .col {
    padding: 0;
  }

  .text {
    flex: 1;
  }

  .icon {
    flex-basis: 20px;
    height: 20px;
    margin: 0 6px 0 0;

    > path {
      fill: $N800;
    }
  }

  .label {
    @extend %font-text-medium-mono;

    color: $N800;
    display: flex;
    margin-bottom: 4px;

    &.disabled {
      cursor: not-allowed;

      .text {
        color: $N300;
      }

      .icon > path {
        fill: $N300;
      }
    }
  }

  .select {
    margin-right: 4px;

    .option > span {
      color: $N800;
    }
  }

  .container {
    display: flex;

    &.column-direction .select {
      margin-bottom: 4px;
      margin-right: 0;
    }
  }

  .input-wrapper {
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .input-container {
    display: flex;

    &.column-direction .input-wrapper {
      margin-bottom: 4px;
      margin-right: 0;
    }
  }

  .checkbox {
    display: inline-flex;
    color: $N900;
    margin-left: 4px;
    margin-top: 4px;
  }

  .column-direction {
    flex-direction: column;
  }
}

:export {
  N200: $N200;
  N300: $N300;
  N800: $N800;
}
