@import '~@holberg/ui-kit/src/scss/core.scss';

.unclassified-examples-container {
  min-width: 240px;
  max-width: 380px;
  border: 2px solid transparent;

  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .header {
    padding: 12px;
    position: relative;

    .title {
      @extend %font-text-medium;

      color: $N800;
      margin: 0;
    }

    .btn-collapse {
      @extend %transition-all;

      opacity: 0;
      padding: 2px;
      position: absolute;
      right: 8px;
      top: 8px;
      visibility: hidden;
    }
  }

  .menu {
    @extend %font-text-medium;

    position: relative;
    border-bottom: 2px solid $N100;
    padding: 0 10px 15px 10px;
    margin-bottom: 20px;

    .menu-collapse {
      padding: 4px 7px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: -15px;
    }

    .pinned {
      svg {
        transform: rotate(-180deg);
      }
    }

    .inner-menu {

      .overlay {
        background: $White;
        border-radius: 5px;
        color: $N900;
        position: absolute;
        height: 0;
        width: 0;
        z-index: 99;
        transition-duration: 0s;
        overflow: hidden;

        .menu-item {
          padding: 8px 5px;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            padding-right: 0px;
            padding-left: 10px;
          }
        }

        ul {
          list-style-type: none;
          margin: 2px 0;
          padding: 0;
          padding-left: 8px;
        }

        .notitle-submenu {
          padding: 0 !important;
        }

        p {
          color: $N800;
          margin: 10px 0 5px 0;
        }

      }

      &:hover {
        .overlay {
          left: 5px;
          top: 0;
          padding: 10px 15px;
          border: 1px solid $N150;
          box-shadow: $S100;
          height: fit-content;
          width: calc(100% - 10px);
          transition-delay: 0.6s;
        }
      }

      .overlay-pinned {
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
        position: relative !important;
        height: fit-content;
        width: 100% !important;
        left: 0 !important;
      }
    }

    .selected {
      background: $Pr100;
      color: $Pr600;
      border-radius: 5px;
      padding: 8px 10px !important;
      cursor: pointer;
    }
  }

  .list-wrap {
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .list {
    padding: 0 8px;
    margin-bottom: 15px;

    &-item {
      margin-bottom: 1px;
    }

    &-stretch-height {
      height: 100%;
      margin-bottom: 0;
    }
  }

  .empty-list {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: column;

      .general-error {
        margin-top: -8px;
      }
    }
  }

  &:hover .header .btn-collapse {
    opacity: 1;

    visibility: visible;
  }
}