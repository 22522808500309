@import '~@holberg/ui-kit/src/scss/core.scss';

.properties-header {
  padding: 24px;
  border-bottom: 1px solid $N150;

  .close-button {
    margin-bottom: 16px;

    .icon {
      transform: rotate(-90deg);
    }
  }

  .title {
    @extend %font-h1;

    color: $N900;
    margin: 0 0 20px;
  }

  .tabs-container {
    position: relative;

    .tabs {
      padding-right: 24px;
    }

    .arrow {
      position: absolute;
      top: 0;
      padding: 4px;
      transform: rotate(90deg);
      box-shadow: $S100;
      z-index: 2;
      background: $White;
    }

    .arrow-prev {
      left: -5px;
      top: 0;
      transform: rotate(270deg);
      box-shadow: $S100;
    }

    .arrow-next {
      right: -5px;
      top: 0;
      transform: rotate(90deg);
      box-shadow: $S100;
    }
  }

  .element {
    height: 200px;
  }
}
