@import '~@holberg/ui-kit/src/scss/core.scss';


.patient-details-header {
    height: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: $N150;

    .warning-msg {
        width: 100%;

        p {
            margin: 0;
        }
    }

    .main-header {
        padding: 6px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .patient-info {
            display: flex;
            align-items: center;

            .profile-icon {
                margin-right: 4px;
                width: 20px;

                path {
                    fill: $N900;
                }
            }

            .row {
                margin-right: 20px;
            }

            .title {
                @extend %font-text-semibold;

                display: flex;
                align-items: center;
                text-decoration: none;
                margin-right: 24px;
                color: $N900;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .switch-wrap {
            align-items: center;
            display: flex;
            position: relative;
            padding: 0 3px;
            border-radius: 5px;

            .editlock-explanatory {
                position: absolute;
                color: $White;
                width: 400px;
                top: 30px;
                background: $Pr500;
                border-radius: 6px;
                padding: 15px 50px 15px 15px;
                right: -2px;
                z-index: 100;

                p {
                    @extend %font-main-semibold;

                    margin: 0 0 10px 0;
                }

                span {
                    @extend %font-text-regular;
                }


                .explanatory-close {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    cursor: pointer;
                    height: 28px;

                    path {
                        fill: $Pr150;
                    }
                }
            }

            .switch-text {
                @extend %font-text-medium;

                color: $N900;
                margin-right: 10px;
            }
        }
    }
}