@import '~@holberg/ui-kit/src/scss/core.scss';

.findings-action-bar {
  @extend %font-text-regular;

  align-items: center;
  border-bottom: 1px solid $N150;
  color: $N800;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding-right: 32px;

  .left-container,
  .right-container {
    align-items: center;
    display: flex;
  }

  .left-container {
    .btn-collapse {
      @extend %transition-all;

      margin: 0 8px;
      opacity: 0;
      padding: 4px;
      visibility: hidden;

      .icon-collapse {
        transform: rotate(180deg);
      }

      &.visible {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .text-badge {
    text-transform: uppercase;
  }

  .action-item {
    margin-right: 8px;
  }

  .hidden {
    display: none;
  }
}
