@import '~@holberg/ui-kit/src/scss/core.scss';

.row {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  .tree {
    max-height: 500px;
  }
}
