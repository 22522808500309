@import '~@holberg/ui-kit/src/scss/core.scss';

.snack-bar {
  @extend %transition-all;

  padding-bottom: 0;

  &.visible {
    padding-bottom: 40px;
  }

  .message {
    position: fixed;
    z-index: 10;
    width: 100%;
  }
}