@import '~@holberg/ui-kit/src/scss/core.scss';

.screenshot-header {
    margin-bottom: 8px;

    p {
        margin: 0;
    }

    div {
        margin: 8px 0;

        p {
            @extend %font-text-semibold;
        }
    }
}

.screenshot-modal {
    display: flex;
    align-items: center;
    min-width: 250px;
    max-width: 90vw !important;
    min-height: 150px;
    max-height: 90vh !important;

    .screenshot-modal-header {
        padding: 0 !important;
    }
}

.custom-slider {
    padding: 10px 10px 0 10px;
}