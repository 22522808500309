@import '~@holberg/ui-kit/src/scss/core.scss';

.example {
  border: 2px solid transparent;
  position: relative;

  >.inner-wrap {
    @extend %transition-all;

    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 0 2px 0 8px;
    position: relative;

    .screenshot,
    .unsynced-warning {
      button {
        padding: 2px;
        margin-right: 2px;
      }
    }

    .tooltip-content {
      max-width: 232px;
    }

    .checkbox {
      @extend %transition-all;

      top: 14px;
      padding-right: 2px;
      opacity: 0;
      visibility: hidden;

      &.hidden {
        visibility: hidden !important;
      }
    }

    .text-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 1px;
      padding: 4px;
      cursor: grab;

      .date {
        @extend %font-text-medium;

        color: $N900;
      }

      .note {
        @extend %font-text-regular;

        color: $N800;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .notActive {
      cursor: not-allowed !important;
      color: $N300 !important
    }

    .btns-container {
      @extend %transition-all;
      align-items: center;
      display: flex;
      position: absolute;
      right: 2px;
      padding-top: 2px;

      &:before {
        @extend %transition-all;

        opacity: 0;
        content: ' ';
      }

      .camera,
      .classify,
      .delete {
        margin: 0 2px;
        padding: 2px;
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &.horizontal-alignment {
    margin-right: 32px;

    >.inner-wrap {
      align-items: center;
    }

    .checkbox {
      top: 0;
    }

    .tooltip {
      width: calc(100% - 145px);
    }

    .text-container {
      width: 100%;
      flex-direction: row;

      .date {
        white-space: nowrap;
      }

      .note {
        padding: 0 8px;
      }
    }

    .btns-container {
      margin-left: auto;
      padding-top: 0;

      .camera,
      .classify,
      .delete {
        margin: 0 4px;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus,
  &.is-active {
    cursor: default;

    >.inner-wrap {
      background-color: $N100;

      .checkbox {
        opacity: 1;
        visibility: visible;
      }

      .camera,
      .classify,
      .delete {
        opacity: 1;
        visibility: visible;
      }

      .btns-container {
        background-color: $N50;

        &:before {
          opacity: 1;
          box-shadow: -36px 0 12px -20px $N50 inset;
          content: ' ';
          height: 100%;
          left: -20px;
          position: absolute;
          top: 0;
          width: 20px;
        }
      }
    }
  }

  &.is-selected {
    >.inner-wrap {
      background-color: $Pr100;

      .checkbox {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover>.inner-wrap {
      background-color: $Pr125;
    }

    &:focus>.inner-wrap {
      background-color: $Pr100;
    }
  }

  .screenshot-actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

}