.container {
  position: relative;
}

.svg-text {
  pointer-events: none;
  user-select: none;
}

.ellipse {
  pointer-events: none;
}

.question-mark {
  position: absolute;
  bottom: 12px;
  left: 12px;

  &:hover {
    cursor: pointer;
  }
}

.maximize-icon {
  position: absolute;
  bottom: 12px;
  right: 12px;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.maximized-head-model-overlay {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding: 20px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.maximized-head-model-overlay .container {
  background: white;
  width: 40%;
}

.control {
  margin-left: 0;
}

.tip {
  margin-top: 4px;
}
