@import '~@holberg/ui-kit/src/scss/core.scss';

.details-page-header {
  padding: 16px 32px;
  background-color: $N100;

  .content {
    display: flex;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .title {
    @extend %font-h2;

    margin-right: 16px;
    color: $N900;
  }

  .row {
    margin-right: 16px;
  }

  .row-title {
    display: inline;
  }

  .descriptors {
    display: flex;
    margin-top: 8px;
  }
}

@media (max-width: 1040px) {
  .details-page-header {
    .row-title {
      display: none;
    }
  }
}
