@import '~@holberg/ui-kit/src/scss/core.scss';

.nested-finding {
  padding: 4px;
  border-radius: 6px;
  background: $White;
}

.index-container {
  max-width: calc(100% - 60px);
  margin-top: 4px;
  min-height: 57px;

  .finding-header {
    margin-right: 0;
  }
}
