@import '~@holberg/ui-kit/src/scss/core.scss';

.filter-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .select {
    width: 170px;
    margin-right: 8px;
    display: inline-block;
  }

  .inputs-container {
    display: flex;
  }

  .remove-button {
    padding: 4px;

    .remove-icon {
      width: 24px;
      height: 24px;
    }
  }
}
