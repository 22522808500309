@import '~@holberg/ui-kit/src/scss/core.scss';

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title {
    @extend %font-text-semibold;

    font-size: 20px;
  }

  .login, .password {
    min-width: 200px;
    width: 100%;
    margin-top: 20px;
  }

  .submit {
    margin-top: 20px;
    min-width: 100px;
    text-align: center;
  }
}
