@import '~@holberg/ui-kit/src/scss/core.scss';

/* stylelint-disable selector-pseudo-class-no-unknown */
:global {
  div.focus-visible {
    border: 2px solid $Pr450;
    border-radius: 2px;
  }
}

.category-header {
  @extend %transition-all;

  display: flex;
  justify-content: space-between;
  padding: 1px 16px;
  width: 100%;
  min-height: 38px;
  background: transparent;
  border: 2px solid transparent;

  .container {
    display: flex;
    align-items: center;

    .move-tooltip {
      max-width: 260px;
      align-self: flex-end;
    }

    .target-button {
      margin-right: 4px;
    }

    .move-button,
    .target-button,
    .add-button {
      @extend %transition-all;

      opacity: 0;
      padding: 4px;
      visibility: hidden;
    }

    .add-button {
      background-color: $White;
      border: 2px solid transparent;
    }

    .move-button {
      padding: 4px 8px;
    }
  }

  .arrow {
    margin-right: 10px;
  }

  .label {
    @extend %font-text-regular;

    color: $N900;
    line-height: 1.8;
    margin-right: 10px;

    &.disabled {
      color: $N800;
    }
  }

  &:hover,
  &:focus {
    cursor: default;
    background: $N50;

    .container {
      .move-button,
      .target-button,
      .add-button {
        @extend %transition-all;

        opacity: 1;
        visibility: visible;
      }

      .add-button {
        border-color: $White;
      }
    }
  }

  &:focus {
    outline: none;
  }
}
