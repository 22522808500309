@import '~@holberg/ui-kit/src/scss/core.scss';

.unit-input {
  width: 47%;
}

.read-only {
  width: auto;
}

