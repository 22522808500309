@import '~@holberg/ui-kit/src/scss/core.scss';

.filter {
  .filters {
    padding: 18px;
    overflow: auto;
    max-height: calc(55vh - 140px);
  }

  .filter-title {
    @extend %font-small-medium;

    display: block;
    color: $N800;

    &:not(:first-child) {
      margin-bottom: 8px;
    }
  }

  &.overflowed {
    overflow: scroll;
  }
}
