@import '~@holberg/ui-kit/src/scss/core.scss';
$copy-actions-div-height: 56px;

.modal.report-preview-modal {
  .content {
    max-height: 720px;
    max-width: 900px;
  }

  .report-copy-actions {
    position: absolute;
    height: $copy-actions-div-height;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background: $White;
    box-shadow: $S200;
    z-index: 99;

    button {
      margin-right: 8px;
    }
  }

  .wrap {
    min-width: 700px;
    margin-top: 20px;
    max-height: 620px;
    overflow-y: auto;
    padding-bottom: $copy-actions-div-height;
  }
}