@import '~@holberg/ui-kit/src/scss/core.scss';

.hidden {
  display: none;
}

.reflex-container {
  padding: 0 8px 8px;
}

.report-section {
  border-radius: 8px;
}

/* stylelint-disable selector-pseudo-class-no-unknown */
:global {
  .reflex-splitter {
    &:hover,
    &:active {
      background: $Pr400 !important;
      border: none !important;
    }
  }
}
