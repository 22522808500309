@import '~@holberg/ui-kit/src/scss/core.scss';

.properties-tabs {
  padding: 24px;
  overflow: auto;
  background: $White;

  .tab {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.properties-header {
  width: 100%;
  background: $White;
}

.error-container {
  width: 100%;
  padding: 24px;
  height: 100%;

  .icon {
    transform: rotate(-90deg);
  }
}

.small-height {
  height: calc(100% - 5px);
}


