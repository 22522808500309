@import '~@holberg/ui-kit/src/scss/core.scss';

.panel {
  background-color: $N50;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 16px;

  .title {
    @extend %font-main-semibold;

    color: $N900;
    margin-bottom: 12px;
    margin-top: 0;
  }
}
