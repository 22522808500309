@import '~@holberg/ui-kit/src/scss/core.scss';

.finding-header {
  @extend %transition-all;

  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
  border-radius: 6px;
  background: transparent;
  border: 2px solid transparent;
  margin: 0 32px 1px 0;
  position: relative;

  .checkbox {
    &.hidden {
      visibility: hidden !important;
    }
  }

  .text-badge {
    text-transform: uppercase;
  }

  .badge {
    height: auto;
    margin: 0 8px;
  }

  .container,
  .actions {
    display: flex;
    align-items: flex-start;

    .button,
    .checkbox {
      @extend %transition-all;

      opacity: 0;
      visibility: hidden;
    }

    .checkbox-tooltip {
      max-width: 232px;
    }

    .arrow-tooltip {
      max-width: 216px;
    }
  }

  .actions {
    @extend %transition-all;

    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
    padding: 2px;

    &:before {
      @extend %transition-all;

      opacity: 0;
      content: ' ';
    }

    .button {
      padding: 4px;

      &:not(:last-child) {
        margin-right: 2px;
      }
    }

    .move-button {
      padding: 3px 8px;
    }
  }

  .move-down {
    transform: rotate(180deg);
  }

  .label-container {
    display: flex;
    align-items: center;
    width: calc(100% - 110px);

    .link-button {
      padding: 4px;

      path {
        fill: $N800;
      }
    }
  }

  .label {
    @extend %font-text-regular;

    color: $N900;
    cursor: grab;
    line-height: 1.8;
    margin: 0 8px;
    max-width: calc(100% - 36px);

    &.disabled {
      color: $N800;
    }
  }

  .main-container {
    width: 100%;
    align-items: center;
  }

  &:hover,
  &[data-focus-visible-added] {
    cursor: default;
    background: $N50;

    .actions {
      background-color: $White;
      box-shadow: $S100;
    }

    .actions,
    .container {

      .button,
      .checkbox {
        @extend %transition-all;

        opacity: 1;
        visibility: visible;

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.is-selected {
    background: $Pr100;

    .container {
      .checkbox {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      background: $Pr125;
    }

    &:focus {
      background: $Pr100;
    }
  }

  .headModel {
    width: 24px;
    height: 24px;
    margin-left: 8px;

    svg {
      width: 24px;
      height: 24px;
      transition: all 0.2s;

      &:hover {
        width: 350px;
        height: 350px;
        background: $White;
        position: absolute;
        z-index: 1;
        transition-delay: 1s;
        border: 1px solid $N150;
      }
    }

    span {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      color: $White;
      height: 100%;
      background-color: $N100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1024px) {
  .finding-header {
    .label {
      word-wrap: break-word;
      hyphens: auto;
    }
  }
}