@import '~@holberg/ui-kit/src/scss/core.scss';

.findings {
  $offset: 300px;

  height: 100%;
  background-color: $White;

  .container {
    display: flex;
    height: calc(100% - 48px);

    &.read-only {
      height: 100%;
    }

    .unclassified-examples-wrap {
      border-right: 1px solid $N150;
    }

    .on-dropover {
      border-color: $Pr500;
      border-radius: 4px;
      transition: border-color 0.2s ease-in;
    }

    .events-trees-wrap {
      overflow-y: auto;
      flex-grow: 1;

      & > * {
        border-bottom: 1px solid $N150;
      }
    }
  }
}
