@import '~@holberg/ui-kit/src/scss/core.scss';

.error-screen-wrapper {
  background-color: $N50;
  height: 100vh;

  .error-screen {
    .container {
      max-width: 340px;
    }
  }
}
