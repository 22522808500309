@import '~@holberg/ui-kit/src/scss/core.scss';

.dragged-item {
  @extend %font-text-medium;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background: $N25;
  height: 35px;
  color: $N900;
  margin: 0;
  padding: 0 10px;
  min-width: 220px;
  display: flex;
  align-items: center;
  position: relative;

  .dragged-items-count {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 99;
    background: $Pr500;
    padding: 2px 5px;
    border-radius: 4px;
    color: $White;
  }

  .dragged-items-showcase {
    position: absolute;
    bottom: -6px;
    background: $N25;
    left: 5px;
    right: 5px;
    height: 6px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }

  .arrow-icon {
    transform: rotate(90deg);
    margin: 0 14px;

    path {
      fill: $N800;
    }
  }
}

.dragged-column {
  @extend %font-text-regular;

  background: $N150;
  min-height: 28px;
  min-width: 250px;
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
}