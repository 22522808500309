@import '~@holberg/ui-kit/src/scss/core.scss';

.findings-warning {
  width: auto;
  max-height: 25px;

  .tooltip-content {
    max-width: 250px;
  }
  
  .warning {
    path {
      fill: $Y600;
    }
  }
}