@import '~@holberg/ui-kit/src/scss/core.scss';

.unit-row {
  display: flex;
  width: 91%;
  justify-content: space-between;
}

.read-only {
  justify-content: start;
}

.input-container {
  width: 91%;
}
