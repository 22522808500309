@import '~@holberg/ui-kit/src/scss/core.scss';

.filter-component {
  width: 170px;
  margin-right: 8px;
  display: inline-block;

  .tree {
    max-height: 40vh;

    &.overflowed {
      max-height: 175px;
    }
  }

  div[class*='Calendar '] {
    position: fixed;
    right: 33px;
  }
}
